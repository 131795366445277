<template>
  <div v-if="project && pages" class="document">
    <BrickAndSubstructurePrintPage1 :pageNumber="currentPage()" :project="project" />
    <BrickAndSubstructurePrintPage2 :pageNumber="currentPage()" :project="project" />
    <BrickAndSubstructurePrintPage3 :pageNumber="currentPage()" :project="project" />
    <SolarPanelsPrintPage1 :pageNumber="currentPage()" :project="project" />
    <SolarPanelsPrintPage2 :pageNumber="currentPage()" :project="project" />
    <CableManagementPrintPage1 :pageNumber="currentPage()" :project="project" />
    <CableManagementPrintPage2 :pageNumber="currentPage()" :project="project" />
    <HAkPrintPage1 :pageNumber="currentPage()" :project="project" />
    <HAkPrintPage2 :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage1 :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage2 :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage3 :pageNumber="currentPage()" :project="project" />
    <InverterPrintPage1 :pageNumber="currentPage()" :project="project" />
    <InverterPrintPage2 :pageNumber="currentPage()" :project="project" />
    <InstallationLocationPrintPage :pageNumber="currentPage()" :project="project" />
    <InternetAccessPrintPage :pageNumber="currentPage()" :project="project" />
    <EarthingUnitPrintPage :pageNumber="currentPage()" :project="project" />
    <WallboxPrintPage :pageNumber="currentPage()" :project="project" />
    <MiscPrintPage :pageNumber="currentPage()" :project="project" />
  </div>
  <div v-else>{{ $t('no_data_found') }}</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ExtendedProject } from '@/helpers/projects';
import BrickAndSubstructurePrintPage1 from '@/views/Service/BrickAndSubstructurePrintPage1';
import BrickAndSubstructurePrintPage2 from '@/views/Service/BrickAndSubstructurePrintPage2';
import BrickAndSubstructurePrintPage3 from '@/views/Service/BrickAndSubstructurePrintPage3';
import SolarPanelsPrintPage1 from '@/views/Service/SolarPanelsPrintPage1';
import SolarPanelsPrintPage2 from '@/views/Service/SolarPanelsPrintPage2';
import CableManagementPrintPage1 from '@/views/Service/CableManagementPrintPage1';
import CableManagementPrintPage2 from '@/views/Service/CableManagementPrintPage2';
import HAkPrintPage1 from '@/views/Service/HAkPrintPage1';
import HAkPrintPage2 from '@/views/Service/HAkPrintPage2';
import ElectricMeterPrintPage1 from '@/views/Service/ElectricMeterPrintPage1';
import ElectricMeterPrintPage2 from '@/views/Service/ElectricMeterPrintPage2';
import ElectricMeterPrintPage3 from '@/views/Service/ElectricMeterPrintPage2';
import InverterPrintPage1 from '@/views/Service/InverterPrintPage1';
import InverterPrintPage2 from '@/views/Service/InverterPrintPage2';
import InstallationLocationPrintPage from '@/views/Service/InstallationLocationPrintPage';
import InternetAccessPrintPage from '@/views/Service/InternetAccessPrintPage';
import EarthingUnitPrintPage from '@/views/Service/EarthingUnitPrintPage';
import WallboxPrintPage from '@/views/Service/WallboxPrintPage';
import MiscPrintPage from '@/views/Service/MiscPrintPage';

export default {
  components: {
    BrickAndSubstructurePrintPage1,
    BrickAndSubstructurePrintPage2,
    BrickAndSubstructurePrintPage3,
    SolarPanelsPrintPage1,
    SolarPanelsPrintPage2,
    CableManagementPrintPage1,
    CableManagementPrintPage2,
    HAkPrintPage1,
    HAkPrintPage2,
    ElectricMeterPrintPage1,
    ElectricMeterPrintPage2,
    ElectricMeterPrintPage3,
    InverterPrintPage1,
    InverterPrintPage2,
    InstallationLocationPrintPage,
    InternetAccessPrintPage,
    EarthingUnitPrintPage,
    WallboxPrintPage,
    MiscPrintPage,
  },
  data() {
    return {};
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getProjectByNumber']),
    pages() {
      return 1;
    },
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),
    currentPage() {
      this.pageCounter += 1;
      return this.pageCounter;
    },
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
  },
  created() {
    this.pageCounter = 0; // create non reactive data property
  },
};
</script>
<style src="./ServicePrintPage.scss" lang="scss" />
<style />
