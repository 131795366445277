<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>5. {{ $t('inverter') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('electricMeterCount') }}</div>
        <div>{{ $t(project.serviceReport.electricMeterCount) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('electricMeterList') }}</div>
        <div>{{ $t(project.serviceReport.electricMeterList) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('intermediateMeterRequestedState') }}</div>
        <div>{{ $t(project.serviceReport.intermediateMeterRequestedState) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('meterCombinationRequestedState') }}</div>
        <div>{{ $t(project.serviceReport.meterCombinationRequestedState) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('disassembledMetersOnSiteState') }}</div>
        <div>{{ $t(project.serviceReport.disassembledMetersOnSiteState) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('disassembledMetersOnSitemeterCascadeRequestedStateState') }}</div>
        <div>{{ $t(project.meterCascadeRequestedState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.storageSerialNumberAttachments"
      fieldName="storageSerialNumberAttachments"
      :maxImages="2"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'InverterPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
