<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2. {{ $t('brickAndUk') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('brickAndUkAssemblyType') }}</div>
        <div>{{ $t(project.serviceReport.brickAndUkAssemblyType) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.serviceBrickCoverWidthAttachments"
      fieldName="brickCoverWidthAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.serviceCorrectBrickHandlingAttachments"
      fieldName="correctBrickHandlingAttachments"
      :maxImages="2"
    />

    <div class="content-box">
      <div class="two-column-grid">
        <div>{{ $t('brickAndUkA2State') }}</div>
        <div>{{ $t(project.serviceReport.brickAndUkA2State) }}</div>
        <div>{{ $t('brickAndUkBState') }}</div>
        <div>{{ $t(project.serviceReport.brickAndUkBState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.serviceBrickAndSubstructureAttachments"
      fieldName="brickAndSubstructureAttachments"
      :maxImages="2"
    />

    <div class="content-box">
      <div class="two-column-grid">
        <div>{{ $t('cableAndPlugAttachedToRailState') }}</div>
        <div>{{ $t(project.serviceReport.cableAndPlugAttachedToRailState) }}</div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'ServicePrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
