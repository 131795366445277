<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <AttachmentsPrintGrid
      :attachments="project.serviceHakAttachments"
      fieldName="hakAttachments"
      :maxImages="4"
    />
    <AttachmentsPrintGrid
      :attachments="project.serviceHakAndZsSameRoomAttachments"
      fieldName="serviceHakAndZsSameRoomAttachments"
      :maxImages="4"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'HAkPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
