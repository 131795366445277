<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>4. {{ $t('MISC') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('briefingAndDocumentationState') }}</div>
        <div>{{ $t(project.serviceReport.briefingAndDocumentationState) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('serviceOptionalNotes') }}</div>
        <div>{{ $t(project.serviceReport.secondaryEquipotentialBusBarPresentState) }}</div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('acNotes') }}</div>
        <div v-html="project.technicalReview.acNotes"></div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('dcNotes') }}</div>
        <div v-html="project.technicalReview.dcNotes"></div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.serviceMiscOptionalAttachments"
      fieldName="serviceMiscOptionalAttachments"
      :maxImages="4"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'MiscPrintPage',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
